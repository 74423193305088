.scrollToTopBtn {
    position: fixed;
  
    bottom: 15px;
    right:35px;
    /* width: 80px;
    height: 75px; */
    /* line-height: 75px; */
    border-radius: 50%;
    opacity: 0;
    color: #fff;
    /* background: red; */
    padding:5px;
    text-align:center;
    vertical-align: middle;
    box-shadow: 0px 0px 3px #000;
    transition: opacity 500ms ease, visibility 500ms ease;
  }
  
  .scrollToTopBtnHidden{
    opacity: 0;
  visibility: hidden;
  
  }
  .scrollToTopBtnVisible {
    opacity: 1 !important;
    
    visibility: visible;
  }