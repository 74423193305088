
.topicsChart:hover{
    
    align-items: center;
}

.topicsChart:hover::before{
    content: "Use [alt] + mouse wheel to zoom in/out";
    position: absolute;
    align-self: center;
    
}
