

  .activeLabel {
    /* color: rgb(0, 0, 0);  */
  }

  .inactiveLabel {
   
    opacity: 0.5;
  }
  .inactiveLabel,.inactiveLabel div{color: rgb(0, 0, 0) !important;}

  .predictedLabel {
    /* color: rgb(0, 0, 0); */
  }

  .invalidLabel {
   
    background-color: rgb(208, 32, 32) !important;
    opacity: 0.6;
  }
