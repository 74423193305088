.textDocumentCard {
    box-shadow: rgba(149, 157, 165, 0.6) 0px 2px 5px;
    border: 0.5px solid black;
    max-height: 100%;
    overflow: auto;
    flex-direction: column;
    
  }
  
  .standaloneCard{
  
  height: fit-content; 
  position: sticky; top: 0;
  background-color: #ebe7ebff;
  z-index: 10;
    
  }

  
  .textDocumentCard.Selected {
    box-shadow: rgba(133, 166, 199, 0.8) 2px 2px 10px 5px  !important;
    /* border: solid rgb(158, 206, 255)  4px !important; */
    background-color: rgb(220, 238, 255) !important;

    
  }

  .textCardInfoSection{
    flex-direction: row
  }

  div.colapsedText {
    max-height: 10vh;
    overflow: hidden;
  
  }
  
  div.colapsedText.compact {
    max-height: 1.2em !important;
    white-space: nowrap;
    overflow: hidden;
  
  }
  
  div.expandedText {
    max-height: 60vh;
    overflow: auto;
  }
  
  a.colapseLink{
  color: blue ;
  margin-bottom: -15px;
  }
  
  .textDocumentCardSelected a.colapseLink{
    
    background-image: linear-gradient(transparent 0%, rgb(220, 238, 255) 50%)  !important;;
  }
  
  a.colapseLink:hover{
    color: orange ;
    text-decoration: underline;
    }
  
    div{
      min-width: 0;
    }