@keyframes blink {
    50% {
      opacity: 0.0;
    }
  }
.blink {
animation: blink 0.5s step-start 0s infinite;
}



.app {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f9f7f7;
  /* background:
      radial-gradient(farthest-side at bottom left,
          rgba(157, 98, 155, 0.5),
          transparent 40%),
      radial-gradient(farthest-corner at bottom right,
          rgba(225, 141, 141, 0.3),
          transparent 30%),
      radial-gradient(at 20% 10%,

          rgb(243 136 40 / 24%),
          transparent 20%),
      radial-gradient(farthest-corner at top right,
          rgba(179 54 179 / 33%),
          transparent 30%); */

}

.popup-tag{
  position:absolute;
  display:none;
  background-color:#785448d4;
  color:white;
  padding:10px;
  font-size:20px;
  font-weight:bold;
  text-decoration:underline;
  cursor:pointer;
  
  }




button, div.nowrap, span.nowrap{
  white-space: nowrap !important; 
  overflow: hidden !important; 
  min-width: 0 !important; 
  text-overflow: ellipsis !important; 
  
  
}

a.noUnderline{
  text-decoration: none;
}

div.colapsedText+a.colapseLink{

  margin-top: -10px;
  padding-top: 10px;
  background-image: linear-gradient(transparent 0%, white 40%);
  }


  span.tag-cloud-tag{
    color: rgb(116, 135, 177);
}


.topicDocumentCard {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 2px 5px;
  
}

.smallButton{
  padding: 0px 5px !important;
  font-weight: 500 !important;
}

.similarityScoreBadge span{
  white-space: nowrap;
}