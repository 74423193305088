.nowrap {
    white-space: nowrap;

}

.textInput{
    min-width: 250px;
    
}
.textInput.small{
    padding: 2px;
    font-size: 10pt;
}

.textInput.medium{
    padding: 4px;
    font-size: 15pt;
}

.fieldValue .editIcon{
    visibility: hidden; 
    margin:-5px 0px;
}

.fieldValue:hover .editIcon{
    visibility: visible; 
}

.miniSpinner {
    padding: 2px !important;
    height: 18px !important;
    width: 18px !important;
}

table.autoUIGrid tr td{
    vertical-align:top;
    
}