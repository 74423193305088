
div.expandingButton .btnContentColapsed {
    display: unset;
}

div.expandingButton .btnContentExpanded {
    display: none;
}

div.expandingButton:hover .btnContentColapsed {
    display: none;
}
div.expandingButton:hover .btnContentExpanded {
    display: unset;
}
