

.confusionMatrix{
    display: grid;
    /* grid-template-columns: repeat(2,minmax(1vw,10vw));
    grid-template-rows: repeat(2, minmax(1vw,10vw)); */
    grid-gap: 1px;
    
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .confusionMatrixItem {
    background: tomato;
    padding: 15px;
    
    margin: 1px;
    color: black;
    font-weight: bold;
    font-size: 1.5em;
    text-align: center;
    flex: 1 0 0px;
    aspect-ratio: 1 / 1 ;
  }
  .confusionMatrixLabel {

  
    
    margin: 1px;
    color: black;
    font-weight: bold;
    font-size: 0.7em;
    text-align: center;
    
    
  }

  .confusionMatrixLabelTop{
    
    overflow: hidden;
    text-overflow: ellipsis;
    /* transform: rotate(-15deg);
    transform-origin: bottom left; */
    align-self: flex-end;
  }