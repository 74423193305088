.generateExamples-textExampleInput{
    border: 0.5px solid lightgray;
    font-size: 9pt !important;
    margin: 2px;
}

.generateExamples-textExampleInput:hover{
    border: 0.8px solid gray;
}
.generateExamples-dislike{
 color: lightgray;
 color: red !important;
 margin: 0px 10px;
 height: 15px;
}

.generateExamples-dislike:hover{
    color: red !important;

   }

   .generateExamples-dislike:hover path{
    
    stroke: gray !important;
  }

  .addExampleBtn{
    padding: 2px 8px !important;
    margin-left: 5px;
  }
  .numberCountInput{
    width: 50px !important;
    padding: 2px;
    margin: 0px 5px;
    font-size: 12pt !important;
    font-weight: 800 !important;

  }